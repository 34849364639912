import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 50% 50%;
  min-height: 60rem;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CategoryCircle = styled.span<{ $color: string }>`
  display: block;
  width: 0.8rem;
  height: 0.8rem;

  background: ${({ $color }) => $color};
  border-radius: 50%;
`;
