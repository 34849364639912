import { FocusEvent, ChangeEvent, useState } from 'react';
import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import { IInputProps, InputType } from './types';
import { formatFloat } from '../../common';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Typography } from '../Typography/Typography';
import Icon, { IconName } from '../Icon/Icon';
import Button from '../Button/Button';

function Input({
  name,
  id,
  label,
  type = 'text',
  onFocus,
  onBlur,
  placeholder = '',
  inputStyle,
  disabled = false,
  autoComplete = 'off',
  autoFocus = false,
  required = false,
  control,
  error,
  isLight = false,
  isFullHeight = false,
  isWithoutBorder = false,
}: IInputProps) {
  const colors = useColors();
  const [focused, setFocused] = useState(false);
  const [swappedType, setSwappedType] = useState<InputType>(type);

  function handleSwapType(e: any) {
    e.stopPropagation();

    if (type !== swappedType) {
      setSwappedType(type);
    } else {
      setSwappedType('text');
    }
  }

  function handleFocus(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFocused(true);
    if (onFocus) onFocus(e);
  }

  function handleBlur(
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onReactHookFormBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  ) {
    setFocused(false);

    if (type === 'float' || type === 'price') {
      e.target.value = formatFloat(e.target.value, type === 'price');
    }

    if (onBlur) {
      onBlur(e);
    }

    onReactHookFormBlur(e);
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  ) {
    if (disabled) return;

    if (type === 'number') {
      e.target.value = e.target.value.replace(/\D/g, '');
    }

    if (type === 'float' || type === 'price') {
      e.target.value = e.target.value.replace(/[^\d.]/g, '');
    }

    onChange(e);
  }

  function renderInput() {
    switch (type) {
      case 'textarea':
        return (
          <SC.InputWrapper
            $isTextarea={true}
            $disabled={disabled}
            $focused={focused}
            $error={!!error}
            $isLight={isLight}
            $isFullHeight={isFullHeight}
            $isWithoutBorder={isWithoutBorder}
          >
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <SC.TextArea
                  id={id}
                  $disabled={disabled}
                  style={inputStyle}
                  placeholder={placeholder}
                  onFocus={handleFocus}
                  autoFocus={autoFocus}
                  autoComplete={autoComplete}
                  {...field}
                  onBlur={e => handleBlur(e, field.onBlur)}
                  onChange={e => handleChange(e, field.onChange)}
                  $isFullHeight={isFullHeight}
                />
              )}
            />
          </SC.InputWrapper>
        );
      case 'phone':
        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <SC.InputWrapper $isTextarea={false} $disabled={disabled} $focused={focused} $error={!!error} $isLight={isLight}>
                <PhoneInput
                  international={false}
                  defaultCountry="US"
                  placeholder={placeholder}
                  value={field.value}
                  autoFocus={autoFocus}
                  onChange={value => {
                    field.onChange(value ?? '');
                  }}
                  onBlur={() => {
                    setFocused(false), field.onBlur();
                  }}
                  onFocus={handleFocus}
                />
              </SC.InputWrapper>
            )}
          />
        );
      case 'password':
        return (
          <SC.InputWrapper $isTextarea={false} $disabled={disabled} $focused={focused} $error={!!error} $isLight={isLight}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <SC.InputElement
                  id={id}
                  $disabled={disabled}
                  style={inputStyle}
                  placeholder={placeholder}
                  type={swappedType}
                  autoFocus={autoFocus}
                  onFocus={handleFocus}
                  autoComplete="new-password"
                  {...field}
                  onBlur={e => handleBlur(e, field.onBlur)}
                  onChange={e => handleChange(e, field.onChange)}
                />
              )}
            />
            <SC.PasswordEye onClick={handleSwapType}>
              {type !== swappedType ? <Icon name={IconName.EyeSlash} /> : <Icon name={IconName.Eye} />}
            </SC.PasswordEye>
          </SC.InputWrapper>
        );
      default:
        return (
          <SC.InputWrapper $isTextarea={false} $disabled={disabled} $focused={focused} $error={!!error} $isLight={isLight}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <SC.InputElement
                  id={id}
                  $disabled={disabled}
                  style={inputStyle}
                  placeholder={placeholder}
                  type={type}
                  autoFocus={autoFocus}
                  onFocus={handleFocus}
                  autoComplete={autoComplete}
                  {...field}
                  onBlur={e => handleBlur(e, field.onBlur)}
                  onChange={e => handleChange(e, field.onChange)}
                />
              )}
            />
          </SC.InputWrapper>
        );
    }
  }

  return (
    <SC.InputContainer $isFullHeight={isFullHeight}>
      {!!label && (
        <Typography variant="label">
          {label} {required && <SC.InputLabelRequired>*</SC.InputLabelRequired>}
        </Typography>
      )}
      {renderInput()}
      {!!error && (
        <SC.InputErrorWrapper>
          <Typography variant="caption" color="error">
            {typeof error.message === 'string' ? error.message : null}
          </Typography>
        </SC.InputErrorWrapper>
      )}
    </SC.InputContainer>
  );
}

export default Input;
