import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import ChatList from '../../components/ChatList/ChatList';
import * as SC from './styles';
import useMessages, { useSession, useSessions } from '../../hooks/useMessages';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { useDispatch, useSelector } from 'react-redux';
import { ISessionsState } from '../../redux/sessions/sessions.types';
import {
  resetUserUnseenMessages,
  sendMessage,
  setFilterDate,
  setMessageNeedsAttention,
  setNeedsAttentionMessage,
} from '../../redux/sessions/sessions.redux';
import { getCustomer, resetCustomer } from '../../redux/customers/customers.redux';
import { ICustomersState } from '../../redux/customers/customers.types';
import { saveQA } from '../../redux/documentation/documentation.redux';
import ChatMessages from '../../components/ChatMessages/ChatMessages';
import { IMessageWithId } from '@tuler/shared';
import { QAValidationType, qAValidation } from '../../schemas';
import PageContent from '../../components/PageContent/PageContent';
import { usePush, useURLParams } from '../../hooks/navigation';
import { ICompanyState } from '../../redux/company/company.types';
import Icon, { IconName } from '../../components/Icon/Icon';
import { IAuthUserState, UserRole } from '../../redux/authUser/authUser.types';
import ModalForm from '../../components/ModalForm/ModalForm';
import { EDateFilter, FormField } from '../../common/types';
import ChatSessionHeader from '../../components/ChatSessionHeader/ChatSessionHeader';
import { IUsersState } from '../../redux/users/users.types';
import { getActiveUser, resetActiveUser } from '../../redux/users/users.redux';
import SearchInput from '../../components/SearchInput/SearchInput';
import Select, { ISelectOption } from '../../components/Select/Select';
import Card from '../../components/Card/Card';

function ChatsPage() {
  const { t } = useTranslation();
  const [hasChats, setHasChats] = useState<boolean>(false);
  const { sessionId: urlSessionId } = useURLParams();
  const dispatch = useDispatch();
  const pushUrl = usePush();
  const { sessions, session, needsAttentionMessage, dateFilter } = useSelector((state: { sessions: ISessionsState }) => state.sessions);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { activeUser } = useSelector((state: { users: IUsersState }) => state.users);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { messages = [], isAssistantTyping = false, isUserTyping = false } = session || {};
  const showSessionHeader = urlSessionId && activeUser?.uid === urlSessionId;
  const oldSessionsUnreadMessageCount = sessions
    .filter(session => session.id !== urlSessionId)
    .reduce((acc, session) => {
      return acc + (session.userMessageUnseenCount ?? 0);
    }, 0);
  useMessages(session.id!);
  useSession(urlSessionId!);
  useSessions(companyId, dateFilter, urlSessionId as string, oldSessionsUnreadMessageCount);

  const qAFormFields: FormField[] = [
    {
      name: 'question',
      defaultValue: needsAttentionMessage?.content || '',
      label: t('documentation.qa.editModalQuestion'),
      placeholder: t('documentation.qa.editModalQuestionPlaceholder'),
      type: 'textarea',
      required: true,
    },
    {
      name: 'answer',
      defaultValue: '',
      label: t('documentation.qa.editModalAnswer'),
      placeholder: t('documentation.qa.editModalAnswerPlaceholder'),
      type: 'textarea',
      required: true,
    },
  ];

  const options: ISelectOption<EDateFilter>[] = Object.values(EDateFilter).map(item => ({
    label: t(`chats.filterCalendar-${item}`),
    value: item,
  }));

  function handleSelectChange(_: string, value: EDateFilter) {
    dispatch(setFilterDate(value));
  }

  function handleSetMessageNeedsAttention(id: string) {
    dispatch(setMessageNeedsAttention(id));
  }

  function handleShowCreateQAModal(message: IMessageWithId) {
    dispatch(setNeedsAttentionMessage(message));
  }

  function handleSaveQA(data: QAValidationType) {
    dispatch(saveQA({ ...data, id: 0 }));
    handleSetMessageNeedsAttention(needsAttentionMessage?.id as string);
    dispatch(setNeedsAttentionMessage(undefined));
  }

  function handleCloseQAModal() {
    dispatch(setNeedsAttentionMessage(undefined));
  }

  function handleSendMessage(sessionId: string, message: string) {
    dispatch(sendMessage({ sessionId, message }));
  }

  // function openCustomer() {
  //   if (user.role === UserRole.superuser) {
  //     pushUrl(`/customer/?customerId=${customer.id}&companyId=${companyId}`);
  //   } else {
  //     pushUrl(`/customer/?customerId=${customer.id}`);
  //   }
  // }

  function handleResetUnseenMessages(sessionId: string) {
    dispatch(resetUserUnseenMessages(sessionId));
  }

  useEffect(() => {
    if (sessions) {
      setHasChats(sessions.length > 0);
    }
  }, [sessions]);

  useEffect(() => {
    if (session?.id) {
      dispatch(getActiveUser(session.id));
    } else {
      dispatch(resetActiveUser());
    }
  }, [session?.id]);

  useEffect(() => {
    return () => {
      dispatch(setFilterDate(EDateFilter.today));
    };
  }, [companyId]);

  return (
    <Layout>
      {needsAttentionMessage && (
        <ModalForm
          title={t('documentation.qa.createModalTitle')}
          fields={qAFormFields}
          onClose={handleCloseQAModal}
          onSubmit={handleSaveQA}
          validationSchema={qAValidation}
          submitButtonText={t('documentation.qa.editModalSubmitText')}
          cancelButtonText={t('documentation.qa.editModalCancelText')}
        />
      )}
      <PageContent
        title={t('chats.title')}
        padding="small"
        actions={
          <>
            <SearchInput onSearch={() => {}} placeholder="Search" expand />
            <Select name="ChatCalendar" value={dateFilter} options={options} onChange={handleSelectChange} expand position="right" />
          </>
        }
      >
        <Card isFullHeight noPadding>
          <SC.ChatWrapper $isEmpty={!hasChats} $noSelectedChat={!urlSessionId && hasChats}>
            <ChatList sessions={sessions} />
            <SC.ChatBody>
              {session.id ? (
                <>
                  {/* {showSessionHeader && <ChatSessionHeader activeUser={activeUser} />} */}
                  <ChatMessages
                    key={session.id}
                    sessionId={session.id}
                    onSendMessage={handleSendMessage}
                    messages={messages}
                    isUserTyping={isUserTyping}
                    isAssistantTyping={isAssistantTyping}
                    scrollToTop={[messages.length, isUserTyping, isAssistantTyping]}
                    onSetMessageNeedsAttention={handleSetMessageNeedsAttention}
                    onAnswerMessage={handleShowCreateQAModal}
                    onResetUnseenMessages={handleResetUnseenMessages}
                    hasUnseenMessages={!!session.userMessageUnseenCount}
                  />
                </>
              ) : (
                <EmptyContent icon={IconName.Message} title={t('chats.noChatsSelected')} />
              )}
            </SC.ChatBody>
          </SC.ChatWrapper>
        </Card>
      </PageContent>
    </Layout>
  );
}

export default ChatsPage;
