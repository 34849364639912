import { useEffect } from 'react';
import { IconName } from '../../components/Icon/Icon';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import SearchInput from '../../components/SearchInput/SearchInput';
import Select from '../../components/Select/Select';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import PromptModal from '../../components/PromptModal/PromptModal';
import { IQAState, IQATag } from '../../redux/qa/qa.types';
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyState } from '../../redux/company/company.types';
import {
  closeDeleteQaModal,
  deleteQAs,
  getQAList,
  getTagColors,
  getTagOrder,
  getTags,
  openQA,
  saveQA,
  selectAllQAs,
  selectQA,
  setFilterPage,
  setFilterSearch,
  setFilterTag,
  showDeleteQaModal,
  unselectAllQAs,
} from '../../redux/qa/qa.redux';
import { usePermission } from '../../hooks/usePermission';
import { usePush, usePushQuery, useURLParams } from '../../hooks/navigation';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import QAItem from '../../components/QAItem/QAItem';
import { QAValidationType } from '../../schemas';
import { EFeatureFlag, IAuthUserState } from '../../redux/authUser/authUser.types';
import Documents from '../../components/Documents/Documents';
import Tabs, { ITabItem } from '../../components/Tabs/Tabs';
import { useDocumentationMeta } from '../../hooks/useDocumentationMeta';
import { IUsersState, UserRole } from '../../redux/users/users.types';
import { IDocumentationState } from '../../redux/documentation/documentation.types';
import { showProcessingBanner } from '../../redux/banner/banner.redux';
import { getUsers } from '../../redux/users/users.redux';
import Pagination from '../../components/Pagination/Pagination';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import { useColors } from '../../hooks/theme';
import Card from '../../components/Card/Card';

enum EQATab {
  QA = 'qa',
  Documents = 'documents',
}

export default function QAPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const push = usePush();
  const pushQuery = usePushQuery();
  const colors = useColors();
  const { tab } = useURLParams();
  const activeTab = (tab || EQATab.QA) as EQATab;
  const { selectedQAIds, openedQAIds, qAList, tagOrder, tagColors, isDeleteQAModalOpen, tags, filter, pageCount } = useSelector(
    (state: { qa: IQAState }) => state.qa,
  );
  const { id } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const {
    documentationMeta: { isProcessingDocument },
  } = useSelector((state: { documentation: IDocumentationState }) => state.documentation);
  const { users } = useSelector((state: { users: IUsersState }) => state.users);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const companyIdPath = user.role === 'superuser' ? `companyId=${id}&` : '';
  const { featureFlags } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { isAdmin } = usePermission();
  useDocumentationMeta(id);
  const hasItems = !!qAList.length;
  const hasSelectedQa = !!selectedQAIds.length;
  const showSelectButtons = isAdmin && hasItems;
  const isAllQAsSelected = selectedQAIds.length === qAList.length;
  const showDocuments = featureFlags[EFeatureFlag.DOCUMENTS_ENABLED] && user.role !== UserRole.default;
  const options: { label: string; value: undefined | IQATag }[] = [
    {
      label: t('qa.filterAll'),
      value: undefined,
    },
    ...tags.map(tag => ({ label: tag.label, value: tag })),
  ];
  const filterTagValue = options.find(tag => tag.value?.id === filter.tag?.id);
  const showPagination = pageCount > 1 && activeTab === EQATab.QA;
  const tabs: ITabItem[] = Object.values(EQATab).map(tab => ({
    onClick: () => handleTabChange(tab),
    title: t(`qa.tabTitle-${tab}`),
    name: tab,
  }));

  function handleSelectChange(_: string, value: undefined | IQATag) {
    dispatch(setFilterTag(value));
    handleTabChange(EQATab.QA);
    dispatch(unselectAllQAs());
  }

  function handleDeleteQA() {
    dispatch(deleteQAs());
  }

  function handleCloseDeleteQA() {
    dispatch(closeDeleteQaModal());
  }

  function handleSearch(value: string) {
    dispatch(setFilterSearch(value));
    handleTabChange(EQATab.QA);
    dispatch(unselectAllQAs());
  }

  function handlePageChange(value: number) {
    dispatch(setFilterPage(value));
  }

  function handleSelectQA(id: number) {
    dispatch(selectQA(id));
  }

  function handleShowDeleteQAModal() {
    dispatch(showDeleteQaModal());
  }

  function handleCreateQA() {
    push(`/qa/edit?${companyIdPath}id=0`);
  }

  function handleOpenQA(id: number) {
    dispatch(openQA(id));
  }

  function handleOpenQAEdit(id: number) {
    push(`/qa/edit?${companyIdPath}id=${id}`);
  }

  function handleSaveQA(data: QAValidationType, id: number) {
    dispatch(saveQA({ ...data, id }));
  }

  function handleTabChange(tab: string) {
    if (activeTab === tab) return;
    pushQuery({ tab });
    dispatch(unselectAllQAs());
  }

  function handleClickAllSelect() {
    if (isAllQAsSelected) {
      dispatch(unselectAllQAs());
    } else {
      dispatch(selectAllQAs());
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(getQAList(id));
    }
  }, [id, filter]);

  useEffect(() => {
    if (id) {
      dispatch(getTags(id));
      dispatch(getTagColors());
      dispatch(getTagOrder(id));
      if (!users.length) {
        dispatch(getUsers());
      }
    }
  }, [id]);

  useEffect(() => {
    dispatch(
      showProcessingBanner({ isVisible: isProcessingDocument, title: t('qa.qaGeneratingTitle'), description: t('qa.qaGeneratingDesc') }),
    );
  }, [isProcessingDocument]);

  useEffect(() => {
    // return to qa page if someone open documents with url
    if (!showDocuments && activeTab === EQATab.Documents) {
      pushQuery({ tab: EQATab.QA });
    }
  }, [activeTab]);

  function getQAItems() {
    return qAList.map(qa => {
      const isOpen = openedQAIds.includes(qa.id);
      const qaFormKey = JSON.stringify(qa);
      const user = users.find(user => user.uid === qa.lastEditor);

      return (
        <QAItem
          key={qaFormKey}
          qa={qa}
          isOpen={isOpen}
          showActionButtons={isAdmin && isOpen}
          showCheckbox={isAdmin}
          isSelected={selectedQAIds.includes(qa.id)}
          onSelect={() => handleSelectQA(qa.id)}
          onOpen={() => handleOpenQA(qa.id)}
          onOpenEdit={() => handleOpenQAEdit(qa.id)}
          onSubmit={handleSaveQA}
          tagOrder={tagOrder}
          tagColors={tagColors}
          companyTags={tags}
          userName={user?.displayName}
          userAvatar={user?.photoUrl}
        />
      );
    });
  }

  function getRenderComponent() {
    switch (activeTab) {
      case EQATab.Documents:
        return <Documents />;
      default:
        return hasItems ? (
          <SC.QAList>{getQAItems()}</SC.QAList>
        ) : (
          <EmptyContent
            title={t('qa.emptyTitle')}
            description={t('qa.emptyDesc')}
            icon={IconName.QA}
            buttonText={t('qa.emptyButton')}
            onButtonClick={handleCreateQA}
            showAction={isAdmin}
          />
        );
    }
  }

  function getActions() {
    return (
      <>
        <SearchInput placeholder={t('qa.searchPlaceholder')} onSearch={handleSearch} expand />
        <Select
          name="qaFilter"
          value={filterTagValue?.value}
          options={options}
          onChange={handleSelectChange}
          position="right"
          hideChevron
          beforeIcon={IconName.Filter}
          tagColors={tagColors}
        />
        {showSelectButtons && (
          <>
            {hasSelectedQa && (
              <Button icon={IconName.Trash} onClick={handleShowDeleteQAModal} variant="danger" noWrap>
                {t('qa.deleteSelectedQAs')}
              </Button>
            )}
            <Button onClick={handleClickAllSelect} variant="secondary" noWrap>
              <Checkbox name="select-all-qas" checked={isAllQAsSelected} color={colors.text.default} />
              {isAllQAsSelected ? t('qa.unselectAllQAs') : t('qa.selectAllQAs')}
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <Layout>
      {isDeleteQAModalOpen && (
        <PromptModal
          variant="danger"
          title={t('qa.deleteTitle')}
          questionKey="qa.deleteQuestion"
          name={selectedQAIds.length.toString()}
          cancelText={t('qa.deleteCancel')}
          submitText={t('qa.deleteSubmit')}
          onClose={handleCloseDeleteQA}
          onSubmit={handleDeleteQA}
        />
      )}
      <PageContent
        title={t('qa.title')}
        actionButtonText={t('qa.add')}
        onClickAction={handleCreateQA}
        hideActionButton={!isAdmin}
        hasData={hasItems}
        actions={getActions()}
        subHeader={showDocuments && <Tabs tabs={tabs} activeTab={activeTab} />}
      >
        <SC.Wrapper>
          <Card noPadding isFullHeight>
            {getRenderComponent()}
          </Card>
        </SC.Wrapper>

        {showPagination && <Pagination activePage={filter.page} onPageChange={handlePageChange} pageCount={pageCount} />}
      </PageContent>
    </Layout>
  );
}
