import { TabsBg } from './../Tabs/styles';
import { styled } from 'styled-components';
import { TPagePadding } from './PageContent';

export const Container = styled.div<{ $isWithoutHeader: boolean }>`
  min-height: 100%;
  height: 100%;

  display: ${({ $isWithoutHeader }) => ($isWithoutHeader ? 'block' : 'grid')};
  grid-template-rows: ${({ $isWithoutHeader }) => ($isWithoutHeader ? 'auto' : 'auto 1fr')};
`;

export const Header = styled.div<{ $padding: TPagePadding }>`
  padding: ${({ $padding }) => {
    const paddings = {
      small: '2.8rem 2.6rem',
      medium: '2.8rem 8.8rem',
    };
    return paddings[$padding];
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding: 2.8rem 2rem;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const ChildrenWrapper = styled.div<{ $withoutPadding: boolean; $padding: TPagePadding }>`
  padding: ${({ theme, $withoutPadding, $padding }) => {
    const paddings = {
      small: `${theme.spacing.s6}`,
      medium: `${theme.spacing.s8} 8.8rem 2.8rem`,
    };
    return $withoutPadding ? '0' : paddings[$padding];
  }};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: ${({ theme, $withoutPadding }) => ($withoutPadding ? 0 : `${theme.spacing.s5} 2.8rem 2rem`)};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
`;
