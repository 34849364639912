import { styled } from 'styled-components';

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 30rem 1fr;
  transition: all 0.3s;
  background-color: ${({ theme }) => theme.colors.bg.layout};
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.main<{ $withPadding: boolean; $isBannerVisible: boolean }>`
  position: relative;
  padding: ${({ $withPadding }) => ($withPadding ? '2.8rem 8.8rem' : 0)};
  padding-bottom: ${({ $isBannerVisible, theme }) => ($isBannerVisible ? theme.spacing.s10 : undefined)};
  flex-grow: 1;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: ${({ $withPadding }) => ($withPadding ? '2.8rem 2rem' : 0)};
  }
`;
