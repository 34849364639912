import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  initDashboard,
  setActiveSessions,
  setAskedQuestionsCount,
  setChatOpen,
  setCallsAnalytics,
  setTopAskedQuestions,
  setCallReasons,
  setCallReasonsLoading,
} from './dashboard.redux';
import { DashboardService } from './dashboard.service';
import { setPageLoading } from '../loader/loader.redux';
import { getDateFilter } from '../../common';
import { ICompanyState } from '../company/company.types';
import { IDashboardState, IGetCallsAnalyticsResponse } from './dashboard.types';

function* initDashboardSaga(): SagaIterator {
  yield put(setPageLoading(true));
  yield put(setCallReasonsLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { dateFilter }: IDashboardState = yield select((state: { dashboard: IDashboardState }) => state.dashboard);
  const dashboardService = new DashboardService();
  const dates = getDateFilter(dateFilter);
  const activeSessions = yield call(dashboardService.getActiveSessions, companyId, dates.start, dates.end);
  const { askedQuestionsCount, topAskedQuestions } = yield call(dashboardService.getAskedQuestions, companyId, dates.start, dates.end);
  const { success, ...callsAnalytics }: IGetCallsAnalyticsResponse = yield call(
    dashboardService.getCallsAnalytics,
    companyId,
    dates.start,
    dates.end,
  );
  const { opened, shown } = yield call(dashboardService.getChatOpenAnalytics, companyId, dates.start, dates.end);

  yield put(setChatOpen({ opened, shown }));
  yield put(setActiveSessions(activeSessions));
  yield put(setAskedQuestionsCount(askedQuestionsCount));
  yield put(setTopAskedQuestions(topAskedQuestions));

  yield put(setPageLoading(false));
  if (callsAnalytics) {
    yield put(setCallsAnalytics({ ...callsAnalytics }));
  }

  const { categories, _ } = yield call(dashboardService.getCallReasonsAnalytics, companyId);
  yield put(setCallReasons(categories));
  yield put(setCallReasonsLoading(false));
}

export function* initDashboardSagas(): SagaIterator {
  yield takeEvery(initDashboard, initDashboardSaga);
}
