import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { createSmartmoving, getActiveSmartmoving, updateSmartmoving } from '../../redux/integrations/integrations.redux';
import { FormField } from '../../common/types';
import Card from '../../components/Card/Card';
import EntityForm from '../../components/EntityForm/EntityForm';
import { SmartmovingValidationType, smartmovingValidation } from '../../schemas';

export default function SmartmovingPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeSmartmoving, isSmartmovingLoading } = useSelector((state: { integrations: IIntegrationsState }) => state.integrations);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const formKey = JSON.stringify(activeSmartmoving);

  const smartmovingFields: FormField[] = [
    {
      name: 'providerKey',
      defaultValue: activeSmartmoving?.providerKey || '',
      label: t('smartmoving.formProviderKey'),
      placeholder: t('smartmoving.formProviderKeyPlaceholder'),
      required: true,
      type: 'password',
    },
    {
      name: 'branchId',
      defaultValue: activeSmartmoving?.branchId || '',
      label: t('smartmoving.formBranchId'),
      placeholder: t('smartmoving.formBranchIdPlaceholder'),
      required: true,
      type: 'password',
    },
    {
      name: 'apiKey',
      defaultValue: activeSmartmoving?.apiKey || '',
      label: t('smartmoving.formApiKey'),
      placeholder: t('smartmoving.formApiKeyPlaceholder'),
      required: true,
      type: 'password',
    },
  ];

  function handleSave(data: SmartmovingValidationType) {
    if (activeSmartmoving?.id) {
      dispatch(updateSmartmoving({ ...data, id: activeSmartmoving?.id }));
    } else {
      dispatch(createSmartmoving(data));
    }
  }

  useEffect(() => {
    if (!companyId) return;
    dispatch(getActiveSmartmoving(companyId));
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack title={t('smartmoving.title')}>
        <Card>
          <EntityForm
            id={String(activeSmartmoving?.id)}
            key={formKey}
            fields={smartmovingFields}
            onSubmit={handleSave}
            validationSchema={smartmovingValidation}
            isLoading={isSmartmovingLoading}
          />
        </Card>
      </PageContent>
    </Layout>
  );
}
