import { useTranslation } from 'react-i18next';
import { ICallReason } from '../../redux/dashboard/dashboard.types';
import { Typography } from '../Typography/Typography';
import Card from '../Card/Card';
import Loader, { LoaderEnum } from '../Loader/Loader';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import * as SC from './styles';
import { useEffect } from 'react';

interface IDashboardCallReasonsProps {
  isLoading: boolean;
  categories: ICallReason[];
}

const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const prepareChartData = (categories: ICallReason[]) => {
  const sortedCategories = [...categories].sort((a, b) => b.count - a.count);

  const chartData = sortedCategories.map(category => ({
    name: category.reason,
    value: category.count,
    color: generateRandomColor(),
    percentage: category.percentage,
  }));

  return chartData;
};

export default function DashboardCallReasons({ isLoading, categories }: IDashboardCallReasonsProps) {
  const { t } = useTranslation();
  const dataForChart = prepareChartData(categories);

  return (
    <Card padding="medium">
      <Typography variant="h1" bold>
        {t('dashboard.callReasons')}
      </Typography>
      {isLoading ? (
        <Loader variant={LoaderEnum.Page} />
      ) : (
        <SC.Wrapper>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={600} height={600}>
              <Pie data={dataForChart} cx="50%" cy="50%" dataKey="value">
                {dataForChart?.map(entry => (
                  <Cell key={`cell-${entry.name}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip wrapperStyle={{ outline: 'none' }} />
            </PieChart>
          </ResponsiveContainer>
          <SC.Categories>
            {dataForChart?.map(entry => (
              <SC.Category key={entry.name}>
                <SC.CategoryCircle $color={entry.color} />
                <Typography variant="label" bold>
                  {entry.name}
                </Typography>
              </SC.Category>
            ))}
          </SC.Categories>
        </SC.Wrapper>
      )}
    </Card>
  );
}
