import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const Sidebar = styled.aside`
  position: sticky;
  top: 0;
  width: 30rem;
  height: 100vh;
  padding: ${({ theme }) => theme.spacing.s6} !important;
  display: flex;
  flex-direction: column;

  transition: all 0.3s;
  overflow-y: auto;
  ${WithCustomScrollbar}

  @media (max-width:1024px) {
    display: none;
  }
`;

export const SidebarButton = styled.button`
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.gray900} !important;
    }
    p {
      color: ${({ theme }) => theme.colors.gray900} !important;
    }
  }
`;
